const Settings = {
    env: "stg",
    webUrl: "https://dentco-stg.revtel2.com",
    apiUrl: "https://dentco-api-stg.revtel2.com",
    mediaHost: "https://s3-ap-northeast-1.amazonaws.com/dentco-stg.revtel2.com/media",
    s3Host: "https://s3-ap-northeast-1.amazonaws.com/dentco-stg.revtel2.com",
    debug: true,
    facebookAppId: "1276459929127208",
    googleClientId: "115128487422-bs5m8q90u3g4ata8lb9ujorusgpqhefj.apps.googleusercontent.com",
    googleAnalyticsId: "UA-127064537-1",
    userType: "mono",
    slsApi: {
      host: "",
    },
    aws: {
      tableOrder: "",
      tableStoreOrder: ""
    },
};

module.exports = Settings;

